.app {
  height: 100%;
  width: 100%;
}

input:focus {
    box-shadow: 0 0 0 0.1rem #e1bee7 !important;
    border: 1px solid #aa66cc !important;
}

input.error {
    box-shadow: 0 0 0 0.1rem #ef9a9a !important;
    border: 1px solid #ff4444 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  transition: background-color 5000s ease-in-out 0s;
}

.pointer {
  cursor: pointer;
}

.nav-tabs {
  margin-top: 0px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.nav-tabs>li.nav-item {
  padding: 5px 20px;
  cursor: pointer;
  font-size: 1.2em;
  z-index: 10;
  border-bottom: 1px solid #aa66cc !important;
}

.nav-tabs>li.nav-item.active {
  border: 1px solid #aa66cc !important;
  border-bottom: none !important;
}

.ag-custom-loading-cell {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}