.EventManagement {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 0.6fr 0.4fr;
  grid-template-rows: auto 55px;
}

.events-loading {
  grid-column: 1;
  grid-row: 1 / 3;
}
.events-grid {
  overflow: hidden;
  grid-column: 1;
  grid-row: 1;
}
.events-button-container {
  display: flex;
}

.ag-theme-alpine {
  height: 100%;
  width: 100%;
}

.event-data {
  grid-column: 2;
  grid-row: 1 / 3;
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinner-container,
.noevent-container {
  text-align: center;
}
.spinner-container div,
.spinner-container span,
.noevent-container span,
.noevent-container button {
  vertical-align: middle;
}
.spinner-container span,
.noevent-container span {
  display: block;
  margin-top: 10px;
}
.event-data-grid {
  grid-column: 2;
  grid-row: 1;
}
.event-data-button-container {
  grid-column: 2;
  grid-row: 2;
}

.events-button-container button,
.event-data-button-container button {
  margin: 0;
  width: 100%;
  height: 100%;
  border-radius: 0;
  text-transform: uppercase;
  box-shadow: none;
  border-left: 1px solid #babfc7;
}

.events-button-container button:hover,
.event-data-button-container button:hover {
  box-shadow: none;
  background-color: #8b53a7 !important;
}
