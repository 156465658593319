.custom-cell-editor-wrapper {
    width: calc(100% + 17*2px);
    height: 100%;
    position: relative;
    left: -17px;
    padding-left: 15px;
}

.custom-cell-editor-container {
    width: calc(100% + 17*2px);
}

.custom-cell-editor {
    width: 100%;
    box-shadow: none !important;
    border: none !important;
    padding-left: 15px;
    padding-right: 40px;
}

.custom-cell-editor:focus {
    outline: none;
    box-shadow: none !important;
    border: none !important;
}

.spinner-custom-cell {
    position: absolute;
    right: 15px;
    top: 30%;
}

.success-animation {
    animation: successAnimation 2s ease-in-out;
}

.fails-animation {
    animation: failsAnimation 2s ease-in-out;
}

.validation-fails-animation {
    animation: validationFailsAnimation 500ms ease-in-out;
}

.ag-react-container {
    width: 100%;
    height: 100%;
}

@keyframes successAnimation {
    0% {
        background-color: #00C851;
    }
    100% {
        background-color: transparent;
    }
}


@keyframes failsAnimation {
    0% {
        background-color: #ff4444;
    }
    100% {
        background-color: transparent;
    }
}

@keyframes validationFailsAnimation {
    0% {
        background-color: #ff4444;
    }
    100% {
        background-color: transparent;
    }
}