.Header {
}

nav {
  height: 70px;
}

.navbar-brand h2 {
  color: #606c76;
}

.react-select-container {
  width: 20vw;
  margin-right: 20px;
}

.react-select__control {
  height: 45px;
  border-color: #aa66cc !important;
}

.react-select__control--is-focused {
  box-shadow: 0 0 0 1px #aa66cc !important;
}

.react-select__input>input {
  box-shadow: none !important;
  border: none !important;
}

.react-select__menu {
  color: #606c76;
}

.react-select__option--is-selected {
  background-color: transparent !important;
  color: #606c76 !important;
}

.react-select__option:hover, .react-select__option--is-focused {
  background-color: #e1bee7 !important;
}