.checkbox-container {
    width: 45px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3px;
}

.selection-checkbox {
    width: 100%;
    height: 15px;
}

.selection-checkbox:focus {
    border: none !important;
    outline: none !important;
    margin-top: 0px !important;
}

.spinner-selection-column {
    margin-top: 6px;
}