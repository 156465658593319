.Dashboard {
  height: 100%;
  width: 100%;
  padding: 25px;
}

.dash-button span {
  display: block;
  margin-top: 10px;
  text-transform: uppercase;
}

.version-container {
  position: absolute;
  left: 0;
  bottom: 0;
  margin: 15px;
}

.version {
  font-weight: 500;
  color: #606c76;
}
