.UserManagement {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 0.7fr 0.3fr;
  grid-template-rows: auto 55px;
}

.users-loading {
  grid-column: 1;
  grid-row: 1 / 3;
}
.users-grid {
  overflow: hidden;
  grid-column: 1;
  grid-row: 1;
}
.users-button-container {
  grid-column: 1;
  grid-row: 2;
}

.ag-theme-alpine {
  height: 100%;
  width: 100%;
}

.profile {
  grid-column: 2;
  grid-row: 1 / 3;
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinner-container,
.noprofile-container {
  text-align: center;
}
.spinner-container div,
.spinner-container span,
.noprofile-container span,
.noprofile-container button {
  vertical-align: middle;
}
.spinner-container span,
.noprofile-container span {
  display: block;
  margin-top: 10px;
}
.profile-grid {
  grid-column: 2;
  grid-row: 1;
}
.profile-button-container {
  grid-column: 2;
  grid-row: 2;
  display: flex;
}

.users-button-container button,
.profile-button-container button {
  margin: 0;
  width: 100%;
  height: 100%;
  border-radius: 0;
  text-transform: uppercase;
  border-radius: 0 !important;
  box-shadow: none !important;
}

.profile-button-container button:last-child {
  border-left: 1px solid #babfc7;
}