.EventsBulkUpdateGrid {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 0.04fr 0.96fr;
}

.header-checkbox {
  padding: 5px;
  background-color: #e1bee7;
  display: flex;
  flex-wrap: nowrap;
  justify-content: left;
  align-items: center;
  box-sizing: border-box;
}

.header-checkbox p {
  margin: 0;
  padding-right: 30px;
  font-weight: bold;
}

.checkbox-container {
  padding-right: 30px;
  display: flex;
}

.checkbox-container input {
  margin-top: 6px;
}

.custom-control.custom-switch {
  margin-left: 5px;
}
