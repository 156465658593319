.dropzone {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dropzone:focus {
    outline: none;
}

i {
    margin-right: 5px;
}