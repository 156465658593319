.CustomColumnHeader {
  height: 100%;
  width: 100%;
}

div.ag-header-cell .ag-react-container {
  width: 100%;
}

.ag-grid-react-select__control {
  height: 45px;
  border-color: #aa66cc !important;
}

.ag-grid-react-select__control--is-focused {
  box-shadow: 0 0 0 1px #aa66cc !important;
}

.ag-grid-react-select__input>input {
  box-shadow: none !important;
  border: none !important;
}

.ag-grid-react-select__menu {
  color: #606c76;
}

.ag-grid-react-select__option--is-selected {
  background-color: transparent !important;
  color: #606c76 !important;
}

.ag-grid-react-select__option:hover, .ag-grid-react-select__option--is-focused {
  background-color: #e1bee7 !important;
}

.not-selected{
  border-color: red;
}

.customHeaderLabel {
  height: 100%;
  width: 100%;
  text-align: center;
}

.center-header {
  line-height: 75px;
}