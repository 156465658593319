  .ag-react-container {
    width: 100%;
    height: 100%;
  }
  
  .ag-grid-react-multiselect-container {
    width: calc(100% + 17*2px);
    height: 100%;
    position: relative;
    left: -17px;
  }

  .success-animation {
    animation: successAnimation 2s ease-in-out;
  }

  .fails-animation {
    animation: failsAnimation 2s ease-in-out;
  }
  
  .ag-grid-react-multiselect__control {
    width: 100%;
    border: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
    line-height: 10px;
  }
  
  .ag-grid-react-multiselect__control--is-focused {
    box-shadow: none !important;
  }
  
  .ag-grid-react-multiselect__input>input {
    box-shadow: none !important;
    border: none !important;
  }

  .ag-grid-react-multiselect__value-container--is-multi {
      height: 100%;
      display: flex !important;
      padding-top: 10px 8px;
  }
  
  .ag-grid-react-multiselect__menu {
    color: #606c76;
    margin-top: -0px !important;
  }
 
  .ag-grid-react-multiselect__option--is-selected {
    background-color: transparent !important;
    color: #606c76 !important;
  }
  
  .ag-grid-react-multiselect__option:hover, .ag-grid-react-multiselect__option--is-focused {
    background-color: #e1bee7 !important;
  }
  
  .ag-grid-react-multiselect__placeholder {
      align-self: flex-end;
  }

  .ag-grid-react-multiselect__multi-value {
     background-color: transparent !important;
     margin-bottom: 5px;
  }

  .ag-grid-react-multiselect__multi-value__label {
    align-self: center;
    height: 100%;
    border-radius: 10px 0 0 10px !important;
    line-height: 16px;
  }

  .ag-grid-react-multiselect__multi-value__remove {
    border-radius: 0 10px 10px 0 !important;
  }

  .ag-grid-react-multiselect__multi-value, .ag-grid-react-multiselect__multi-value__label, .ag-grid-react-multiselect__multi-value__remove  {
      background-color: #e1bee7;
  }
  
  .ag-grid-react-multiselect__dropdown-indicator {
    align-self: flex-start;
  }
  
  .ag-grid-react-multiselect__clear-indicator {
    align-self: flex-start;
  }

  @keyframes successAnimation {
    0% {
        background-color: #00C851;
        z-index: 1000;
    }
    100% {
        background-color: transparent;
    }
  }


  @keyframes failsAnimation {
      0% {
          background-color: #ff4444;
      }
      100% {
          background-color: transparent;
      }
  }