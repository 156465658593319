.EventsBulkUpdate {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 55px;
}

.events-bulk-grid {
  overflow: hidden;
  grid-column: 1;
  grid-row: 1;
}

input[type="file"] {
  display: none;
}

.events-bulk-buttons-container {
  display: flex;
  grid-column: 1;
  grid-row: 2;
}

.events-bulk-buttons-container button {
  margin: 0;
  width: 100%;
  height: 100%;
  border-radius: 0;
  text-transform: uppercase;
  box-shadow: none;
  border-left: 1px solid #babfc7;
}

.events-bulk-buttons-container button i {
  margin-right: 5px;
}

.events-bulk-buttons-container button:hover {
  box-shadow: none;
  background-color: #8b53a7 !important;
}
