.AppLayout,
.container-fluid {
  height: 100%;
  width: 100%;
  padding: 0;
}

main {
  height: calc(100% - 70px);
  width: 100%;
  padding: 0;
}
