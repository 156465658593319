.ProfileGrid {
  height: 100%;
  width: 100%;
}

.profile-header-container {
  max-height: 175px;
  width: 100%;
  display: grid;
  grid-template-columns: 175px;
  grid-template-rows: 135px 40px;
  grid-template-areas: 
    "imgn data"
    "imgn tabs";
}

.img-container {
  position: relative;
  grid-area: imgn;
  object-fit: contain;
  height: 175px;
}

.ReactCrop div {
  height: 175px;
}

.ReactCrop__image {
  object-fit: contain;

}

.profile-title-container {
  grid-area: data;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.profile-title-user-names {
  font-weight: 500;
  font-size: 1.5em;
}

.profile-title-user-email {
  font-size: 1.1em;
}

.grid-container.ag-theme-alpine {
  height: calc(100% - 175px);
}

.profile-kvp-switch {
  width: 100%;
  display: grid !important;
  grid-template-columns: 5fr 5fr;
  grid-area: tabs;
}

.profile-kvp-switch button {
  border-radius: 0 !important;
  box-shadow: none !important;
  margin: 0 !important;
}

.profile-kvp-switch button:last-child {
  border-left: 1px solid #babfc7;
}

.profile-image-input {
  font-size: .8em;
  padding: 5px;
  background-color: #f3f5f7;
  border: 1px solid #aa66cc;
  border-radius: 10px;
  display: block;
  height: 30px;
  width: max-content;
  cursor: pointer;
  align-self: flex-end;
}

.center-spinner {
  position: absolute;
  top: 40%;
  left: 40%;
}